* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
ol,
ul,
dl {
  margin: 0;
}
a {
  cursor: pointer;
  outline: none !important;
  text-decoration: none !important;
}
p {
  margin: 0;
}
li {
  list-style-type: none;
}
img {
  border: none;
  display: block;
}
svg {
  display: block;
}
video {
  display: block;
  width: 100%;
}
body {
  direction: rtl;
  font-family: "IRANSans", Tahoma, Geneva, Verdana, sans-serif;
  min-width: 360px;
  user-select: none;
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 900;
  src: url('/fonts/iran-sans/IRANSans_Black.eot');
  src: url('/fonts/iran-sans/IRANSans_Black.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('/fonts/iran-sans/IRANSans_Black.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('/fonts/iran-sans/IRANSans_Black.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('/fonts/iran-sans/IRANSans_Black.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: bold;
  src: url('/fonts/iran-sans/IRANSans_Bold.eot');
  src: url('/fonts/iran-sans/IRANSans_Bold.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('/fonts/iran-sans/IRANSans_Bold.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('/fonts/iran-sans/IRANSans_Bold.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('/fonts/iran-sans/IRANSans_Bold.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/iran-sans/IRANSans_Medium.eot');
  src: url('/fonts/iran-sans/IRANSans_Medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('/fonts/iran-sans/IRANSans_Medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('/fonts/iran-sans/IRANSans_Medium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('/fonts/iran-sans/IRANSans_Medium.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/iran-sans/IRANSans_Light.eot');
  src: url('/fonts/iran-sans/IRANSans_Light.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('/fonts/iran-sans/IRANSans_Light.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('/fonts/iran-sans/IRANSans_Light.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('/fonts/iran-sans/IRANSans_Light.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/iran-sans/IRANSans_UltraLight.eot');
  src: url('/fonts/iran-sans/IRANSans_UltraLight.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('/fonts/iran-sans/IRANSans_UltraLight.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('/fonts/iran-sans/IRANSans_UltraLight.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('/fonts/iran-sans/IRANSans_UltraLight.ttf') format('truetype');
}
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/iran-sans/IRANSans.eot');
  src: url('/fonts/iran-sans/IRANSans.eot?#iefix') format('embedded-opentype'), /* IE6-8 */ url('/fonts/iran-sans/IRANSans.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/ url('/fonts/iran-sans/IRANSans.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('/fonts/iran-sans/IRANSans.ttf') format('truetype');
}
.weeknames {
  border-radius: 10px;
  background-color: #96bd8f !important;
}
.weeknames * {
  padding: 10px;
}
.day {
  min-height: 140px;
  max-height: 140px;
  border: 2px solid #d9d9d9;
  margin-bottom: 4px;
  font-weight: 400;
  height: 100%;
  transition: all ease-out 0.1s;
  padding: 5px;
  position: relative;
  overflow-y: auto;
  border-radius: 10px;
}
.day .title {
  font-size: 0.9em;
}
.day .day-number {
  font-size: 18px;
  font-weight: 500;
}
.day.today {
  border-color: #96bd8f;
  background-color: #b1fd0034;
  color: #96bd8f !important;
}
.day:hover {
  border-color: #262626;
  cursor: pointer;
}
.day.event {
  border-color: #52c41a !important;
}
.day.event:hover {
  background-color: #a0d91167;
}
.day.holiday {
  border-color: #f5222d !important;
  color: #f5222d;
  background-color: #ff634727 !important;
}
.day.passed {
  pointer-events: none;
  background-color: #fafafa;
}
.ant-float-btn {
  inset-block-end: 24px;
}
.ant-table-thead > tr > th {
  padding: 16px 8px;
}
.ant-table-thead .center-cell {
  text-align: center !important;
}
.ant-table-tbody > tr > td {
  padding: 8px;
}
.ant-table-tbody > tr .ant-table {
  margin: -8px !important;
}
.ant-table-tbody .center-cell {
  text-align: center;
}
.ant-table-tbody .desc-cell .name {
  display: block;
  line-height: 20px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-table-tbody .desc-cell .desc {
  color: #262626;
  display: block;
  height: 12px;
  line-height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-table-tbody .desc-cell .cropped {
  width: 204px;
}
.ant-table-tbody .desc-cell .icon {
  float: right;
  line-height: 20px;
}
.ant-table-tbody .desc-cell .icon + .name {
  margin-right: 20px;
}
.ant-table-tbody .ltr-cell {
  direction: ltr;
}
.ant-table-tbody .profile-cell {
  padding-right: 48px !important;
  position: relative;
}
.ant-table-tbody .profile-cell .ant-avatar {
  position: absolute;
  right: 8px;
  top: 8px;
}
.ant-table-tbody .profile-cell .name {
  display: block;
  line-height: 20px;
  height: 20px;
}
.ant-table-tbody .profile-cell .desc {
  color: #262626;
  display: block;
  height: 12px;
  line-height: 12px;
}
.ant-layout-footer {
  padding: 24px 16px;
}
.sp-divider {
  background-color: #f0f0f0;
  border: none;
  display: block;
  height: 1px;
}
.sp-input-ltr {
  direction: ltr;
}
.sp-center-spin {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sp-carousel {
  border-radius: 8px;
  margin-bottom: 16px;
}
.sp-carousel .ant-avatar {
  margin-left: 8px;
}
.sp-carousel .ant-card-body {
  cursor: pointer;
  line-height: 36px;
  padding: 8px 8px 8px 16px;
}
.sp-carousel .selected {
  background-color: #96bd8f;
  height: 10px;
}
.sp-carousel .slick-slide + .slick-slide {
  margin-left: 16px;
}
.sp-tabs .ant-tabs-tab-btn a {
  color: inherit;
  display: block;
  padding: 12px 0;
}
.sp-tabs .ant-tabs-tab {
  padding: 0;
}
.sp-form-drawer .ant-drawer-footer .ant-btn {
  float: left;
}
.sp-form-drawer .ant-drawer-footer .ant-btn + .ant-btn {
  margin-left: 8px;
}
.sp-default-drawer .ant-drawer-body {
  padding: 0;
}
.sp-default-logo {
  display: block;
  height: 64px;
  padding: 4px 8px;
}
.sp-default-logo img {
  display: block;
  height: 100%;
  margin: 0 auto;
}
.sp-default-menu {
  border: none !important;
}
.avatar-icon {
  display: block;
  font-size: 18px;
  line-height: 78px;
  color: "#eb2f96";
}
.sp-upload-image .ant-upload .image {
  max-height: 100%;
  max-width: 100%;
}
.sp-upload-image .ant-upload .icon {
  display: block;
  font-size: 18px;
  line-height: 78px;
}
.sp-upload-image .ant-upload .uploading {
  display: block;
  margin-top: 16px;
}
.sp-upload-image .ant-upload .ant-progress-bg {
  height: 2px !important;
}
.sp-upload-image .ant-upload .ant-progress {
  padding: 0 8px;
}
.sp-upload-image .ant-upload .ant-upload {
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  height: 80px;
  min-width: 80px;
  text-align: center;
  transition: border-color 0.3s;
}
.sp-upload-image .ant-upload .ant-upload:hover {
  border-color: #96bd8f;
}
.sp-auth-layout {
  background-image: url(/images/auth-layout-bg.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  min-height: 100vh;
}
.sp-auth-layout .brand {
  display: block;
  height: 64px;
  margin: 0 auto 32px;
  overflow: hidden;
}
.sp-auth-layout .heading {
  font-size: 18px;
  margin: 0 auto 32px;
  text-align: center;
}
.sp-auth-layout .ant-form-item-label {
  display: none;
}
.sp-auth-layout .wrapper {
  margin: 0 auto;
  max-width: 400px;
  padding: 64px 16px;
  width: 100%;
}
.sp-default-layout .ant-breadcrumb {
  padding: 16px 0;
}
.sp-default-layout .ant-layout {
  background-color: #fff;
  min-height: 100vh;
}
.sp-default-layout .ant-layout-content {
  margin-top: 64px;
  padding: 0 16px;
  position: relative;
}
.sp-default-layout .ant-layout-header {
  border-bottom: solid 1px #f0f0f0;
  left: 0;
  background-color: #d9d9d9;
  padding: 0 16px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}
.sp-default-layout .ant-layout-header .ant-dropdown-trigger {
  cursor: pointer;
  float: left;
}
.sp-default-layout .ant-layout-header .ant-dropdown-trigger .ant-avatar {
  margin-left: 8px;
}
.sp-default-layout .ant-layout-header .drawer-trigger {
  cursor: pointer;
  float: right;
  margin: 21px -4px 0 8px;
  padding: 4px;
}
.sp-default-layout .ant-layout-header .heading {
  float: right;
}
.sp-default-layout .ant-layout-sider {
  border-left: solid 1px #f0f0f0;
  background-color: #f6f6f6;
  height: 100vh;
  right: 0;
  overflow: auto;
  position: fixed;
}
.sp-default-layout .ant-page-header {
  padding: 16px 0;
}
.ant-menu-light {
  background-color: #f6f6f6;
}
.ant-drawer-body {
  background-color: #f6f6f6;
}
.ant-menu-submenu {
  margin-top: 5px;
}
.ant-menu-submenu-title {
  background-color: #fff;
}
.ant-menu-submenu-title:active {
  background-color: #000;
}
.ant-menu-item-selected {
  background-color: #000;
}
.ant-menu-item-active {
  background-color: #96bd8f;
}
.ant-menu-item {
  background-color: #fff;
}
.sp-result-layout {
  min-height: 100vh;
}
@media (min-width: 768px) {
  .ant-layout-footer {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .sp-default-layout .ant-layout {
    margin-right: 260px;
  }
  .sp-default-layout .ant-layout-header {
    right: 260px;
  }
}
@media (max-width: 767px) {
  .sp-form-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .calendar-jalali {
    display: none;
  }
  .ant-modal {
    height: 100vh;
    margin: 0;
    min-width: 320px;
    max-width: none;
    top: 0;
    padding: 0;
    width: 100% !important;
  }
  .ant-modal .ant-modal-close {
    position: fixed;
  }
  .ant-modal .ant-modal-header {
    background-color: #ffffff;
    height: 56px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .ant-modal .ant-modal-footer {
    background-color: #ffffff;
    bottom: 0;
    height: 74px;
    left: 0;
    position: fixed;
    right: 0;
  }
  .ant-modal .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
    min-height: 100%;
    padding-bottom: 74px;
    padding-top: 56px;
    position: relative;
  }
}
.slick-slide {
  width: 100%;
  margin-bottom: 30px;
}
.slick-slide > div .student-card-fixer {
  width: 100%;
}
.slick-slide > div .student-card-fixer .ant-card {
  max-height: min-content;
  max-width: max-content;
}
.slick-slide > div .student-card-fixer .ant-card:hover {
  background-color: #c0d7bc;
}
.slick-slide > div .student-card-fixer .ant-card:focus {
  background-color: #abcaa5;
}
.slick-slide > div .student-card-fixer .nametag {
  text-overflow: ellipsis;
}
.bar-card {
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex !important;
  width: 400px;
  background-color: #ececec;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.bar-row {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}
.edugeneralreport-bar {
  margin: 0 auto;
  display: flex !important;
  width: 300px;
  background-color: #ececec;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.bar-col {
  margin: 0 auto;
  text-align: center;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  padding-left: 10px;
  padding-right: 10px;
  color: unset;
  width: 40px;
  font-size: unset;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}
.test {
  width: 96%;
  margin-bottom: 30px !important;
  margin: auto;
}
.calendar {
  margin-top: "30px";
  padding-left: "30px";
  padding-right: "30px";
}
.stOverviewCard {
  width: 320px;
  margin: auto;
  margin-bottom: 30px;
}
.edubar {
  margin: 0 auto;
  text-align: center;
}
.user-info-flex {
  display: flex !important;
  flex-direction: row;
  margin: auto;
  width: 700px;
  justify-content: center;
}
.user-info-flex .div {
  justify-content: center;
  flex-direction: row;
}
/* Style the badge */
.badge {
  background-color: red !important;
  width: 5px !important;
  height: 5px !important;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 10px;
}
.rolf-form-title {
  margin-top: 30px;
  font-weight: bold;
}
.remaining-file {
  background-color: #c0d7bc;
}
.remaining-file .remaining-students-file {
  margin-top: 5px;
}
.bullet-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  /* Adjust the spacing between items if necessary */
}
.bullet {
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  padding-left: 10px;
}
.ant-picker-calendar {
  margin: auto;
  width: 700px !important;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.ant-picker-body {
  background-color: #f5f5f5;
}
.user-info-card-data {
  width: "100%";
  row-gap: "16px";
  display: "flex";
  flex-wrap: "wrap";
  margin-top: "16px";
  align-items: "center";
  justify-content: "space-evenly";
}
.password-toggle-icon {
  color: #1890ff;
  /* Set the color of the icon */
}
.download-csv {
  margin: auto;
  background-color: #96bd8f;
  text-align: center;
  color: "#fff";
}
.financial-Page-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.video-box .video-cards {
  background-color: #f1f1f1;
  padding-top: 2%;
  border: 0.05em solid gray;
  border-radius: 5px;
}
.row-top-table {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5em;
  padding-left: 5em;
}
.row-top-table .open-button {
  background-color: #96bd8f;
  height: 3em;
}
.row-top-table .close-button {
  background-color: #96bd8f;
  height: 3em;
  color: #fff;
  background-color: #ff0909;
}
.row-top-table .close-button:hover {
  background-color: #96bd8f;
  height: 3em;
  color: #fff !important;
  background-color: #ff7070;
}
.row-top-table .warning-button {
  background-color: #988fbd;
  color: #fff;
  height: 3em;
}
.row-top-table .warning-button:hover {
  background-color: #ada5ca;
  color: #fff !important;
}
.row-top-table .borrowedStudents {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  border-bottom: 1px solid #e8e8e8;
  /* Optional: to create a table-like row effect */
  /* Style for the student name */
}
.row-top-table .borrowedStudents span {
  flex: 1;
  /* Takes available space */
  font-size: 16px;
}
